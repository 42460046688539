/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.3 public/models/646d9dcdc8a5f5bddbfac913.glb -o src/components/Avatar.jsx -r public
*/

import { useAnimations, useFBX, useGLTF } from "@react-three/drei";
import { useFrame, useLoader } from "@react-three/fiber";
import { useControls } from "leva";
import React, { useEffect, useMemo, useRef, useState } from "react";

import * as THREE from "three";

const corresponding = {
  A: "viseme_PP",
  B: "viseme_kk",
  C: "viseme_I",
  D: "viseme_AA",
  E: "viseme_O",
  F: "viseme_U",
  G: "viseme_FF",
  H: "viseme_TH",
  X: "viseme_PP",
};


function base64ToBlob(base64, contentType = "audio/mpeg") {
  const byteCharacters = atob(base64);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += 512) {
    const slice = byteCharacters.slice(offset, offset + 512);
    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: contentType });
}

export function Avatar({ position, scale, playAudio, count, audioFile, jsonDoc }) {
  //   const {
  //     playAudio,
  //     script,
  //     headFollow,
  //     smoothMorphTarget,
  //     morphTargetSmoothing,
  //   } = useControls({
  //     playAudio: false,
  //     headFollow: true,
  //     smoothMorphTarget: true,
  //     morphTargetSmoothing: 0.5,
  //     script: {
  //       value: "welcome",
  //       options: ["welcome", "pizzas"],
  //     },
  //   });

  const [audioUrl, setAudioUrl] = useState(null)

  useEffect(() => {
    if (audioFile) {
      const blob = base64ToBlob(audioFile);
      const url = URL.createObjectURL(blob);
      setAudioUrl(url);

      return () => {
        URL.revokeObjectURL(url); // Cleanup the URL when the component unmounts or `audioFile` changes
      };
    }
  }, [audioFile]);

  const script = {
    value: "pizzas",
    options: ["welcome", "pizzas"],
  };
  const headFollow = true;
  const smoothMorphTarget = true;
  const morphTargetSmoothing = 0.5;

  const audio = useMemo(
    () => new Audio("data:audio/mp3;base64," + audioFile),
    [count]
  );
  const jsonFile = useLoader(THREE.FileLoader, `audios/${script.value}.json`);
  const lipsync = JSON.parse(jsonFile);

  useFrame(() => {
    const currentAudioTime = audio.currentTime;
    
    if (audio.paused || audio.ended) {
      setAnimation("Idle");
      return;
    }

    Object.values(corresponding).forEach((value) => {
      if (!smoothMorphTarget) {
        nodes.Wolf3D_Head.morphTargetInfluences[
          nodes.Wolf3D_Head.morphTargetDictionary[value]
        ] = 0;
        nodes.Wolf3D_Teeth.morphTargetInfluences[
          nodes.Wolf3D_Teeth.morphTargetDictionary[value]
        ] = 0;
      } else {
        nodes.Wolf3D_Head.morphTargetInfluences[
          nodes.Wolf3D_Head.morphTargetDictionary[value]
        ] = THREE.MathUtils.lerp(
          nodes.Wolf3D_Head.morphTargetInfluences[
            nodes.Wolf3D_Head.morphTargetDictionary[value]
          ],
          0,
          morphTargetSmoothing
        );

        nodes.Wolf3D_Teeth.morphTargetInfluences[
          nodes.Wolf3D_Teeth.morphTargetDictionary[value]
        ] = THREE.MathUtils.lerp(
          nodes.Wolf3D_Teeth.morphTargetInfluences[
            nodes.Wolf3D_Teeth.morphTargetDictionary[value]
          ],
          0,
          morphTargetSmoothing
        );
      }
    });

    // if(jsonDoc){
    //   for (let i = 0; i < jsonDoc.mouthCues.length; i++) {
    //     const mouthCue = jsonDoc.mouthCues[i];
    //     if (
    //       currentAudioTime >= mouthCue.start &&
    //       currentAudioTime <= mouthCue.end
    //     ) {
    //       if (!smoothMorphTarget) {
    //         nodes.Wolf3D_Head.morphTargetInfluences[
    //           nodes.Wolf3D_Head.morphTargetDictionary[
    //             corresponding[mouthCue.value]
    //           ]
    //         ] = 1;
    //         nodes.Wolf3D_Teeth.morphTargetInfluences[
    //           nodes.Wolf3D_Teeth.morphTargetDictionary[
    //             corresponding[mouthCue.value]
    //           ]
    //         ] = 1;
    //       } else {
    //         nodes.Wolf3D_Head.morphTargetInfluences[
    //           nodes.Wolf3D_Head.morphTargetDictionary[
    //             corresponding[mouthCue.value]
    //           ]
    //         ] = THREE.MathUtils.lerp(
    //           nodes.Wolf3D_Head.morphTargetInfluences[
    //             nodes.Wolf3D_Head.morphTargetDictionary[
    //               corresponding[mouthCue.value]
    //             ]
    //           ],
    //           1,
    //           morphTargetSmoothing
    //         );
    //         nodes.Wolf3D_Teeth.morphTargetInfluences[
    //           nodes.Wolf3D_Teeth.morphTargetDictionary[
    //             corresponding[mouthCue.value]
    //           ]
    //         ] = THREE.MathUtils.lerp(
    //           nodes.Wolf3D_Teeth.morphTargetInfluences[
    //             nodes.Wolf3D_Teeth.morphTargetDictionary[
    //               corresponding[mouthCue.value]
    //             ]
    //           ],
    //           1,
    //           morphTargetSmoothing
    //         );
    //       }
  
    //       break;
    //     }
    //   }
    // }
  });

  useEffect(() => {
    // nodes.Wolf3D_Head.morphTargetInfluences[
    //   nodes.Wolf3D_Head.morphTargetDictionary["viseme_I"]
    // ] = 1;
    // nodes.Wolf3D_Teeth.morphTargetInfluences[
    //   nodes.Wolf3D_Teeth.morphTargetDictionary["viseme_I"]
    // ] = 1;
    if (playAudio) {
      audio.play();
      if (script === "welcome") {
        // setAnimation("Greeting");
      } else {
        // setAnimation("Angry");
      }
    } else {
      // setAnimation("Idle");
      audio.pause();
    }

    console.log(jsonDoc, "jsonDoc");
    
    
  }, [playAudio, count]);

  const { nodes, materials } = useGLTF("/models/646d9dcdc8a5f5bddbfac913.glb");
  const { animations: idleAnimation } = useFBX("/animations/Idle.fbx");
  const { animations: angryAnimation } = useFBX(
    "/animations/Angry Gesture.fbx"
  );
  const { animations: greetingAnimation } = useFBX(
    "/animations/Standing Greeting.fbx"
  );

  idleAnimation[0].name = "Idle";
  angryAnimation[0].name = "Angry";
  greetingAnimation[0].name = "Greeting";

  const [animation, setAnimation] = useState("Idle");

  const group = useRef();
  const { actions } = useAnimations(
    [idleAnimation[0], angryAnimation[0], greetingAnimation[0]],
    group
  );

  useEffect(() => {
    actions[animation].reset().fadeIn(0.5).play();
    return () => actions[animation].fadeOut(0.5);
  }, [animation]);

  // CODE ADDED AFTER THE TUTORIAL (but learnt in the portfolio tutorial ♥️)
  useFrame((state) => {
    if (headFollow) {
      group.current.getObjectByName("Head").lookAt(state.camera.position);
    }
  });

  return (
    <group position={position} scale={scale} dispose={null} ref={group}>
      <primitive object={nodes.Hips} />
      <skinnedMesh
        geometry={nodes.Wolf3D_Body.geometry}
        material={materials.Wolf3D_Body}
        skeleton={nodes.Wolf3D_Body.skeleton}
      />
      <skinnedMesh
        geometry={nodes.Wolf3D_Outfit_Bottom.geometry}
        material={materials.Wolf3D_Outfit_Bottom}
        skeleton={nodes.Wolf3D_Outfit_Bottom.skeleton}
      />
      <skinnedMesh
        geometry={nodes.Wolf3D_Outfit_Footwear.geometry}
        material={materials.Wolf3D_Outfit_Footwear}
        skeleton={nodes.Wolf3D_Outfit_Footwear.skeleton}
      />
      <skinnedMesh
        geometry={nodes.Wolf3D_Outfit_Top.geometry}
        material={materials.Wolf3D_Outfit_Top}
        skeleton={nodes.Wolf3D_Outfit_Top.skeleton}
      />
      <skinnedMesh
        geometry={nodes.Wolf3D_Hair.geometry}
        material={materials.Wolf3D_Hair}
        skeleton={nodes.Wolf3D_Hair.skeleton}
      />
      <skinnedMesh
        name="EyeLeft"
        geometry={nodes.EyeLeft.geometry}
        material={materials.Wolf3D_Eye}
        skeleton={nodes.EyeLeft.skeleton}
        morphTargetDictionary={nodes.EyeLeft.morphTargetDictionary}
        morphTargetInfluences={nodes.EyeLeft.morphTargetInfluences}
      />
      <skinnedMesh
        name="EyeRight"
        geometry={nodes.EyeRight.geometry}
        material={materials.Wolf3D_Eye}
        skeleton={nodes.EyeRight.skeleton}
        morphTargetDictionary={nodes.EyeRight.morphTargetDictionary}
        morphTargetInfluences={nodes.EyeRight.morphTargetInfluences}
      />
      <skinnedMesh
        name="Wolf3D_Head"
        geometry={nodes.Wolf3D_Head.geometry}
        material={materials.Wolf3D_Skin}
        skeleton={nodes.Wolf3D_Head.skeleton}
        morphTargetDictionary={nodes.Wolf3D_Head.morphTargetDictionary}
        morphTargetInfluences={nodes.Wolf3D_Head.morphTargetInfluences}
      />
      <skinnedMesh
        name="Wolf3D_Teeth"
        geometry={nodes.Wolf3D_Teeth.geometry}
        material={materials.Wolf3D_Teeth}
        skeleton={nodes.Wolf3D_Teeth.skeleton}
        morphTargetDictionary={nodes.Wolf3D_Teeth.morphTargetDictionary}
        morphTargetInfluences={nodes.Wolf3D_Teeth.morphTargetInfluences}
      />
    </group>
  );
}

useGLTF.preload("/models/646d9dcdc8a5f5bddbfac913.glb");
